export const PROJECT_NAME = 'HUTTAGE';
export const BASE_URL = 'https://api.huttage.co';
export const WEBSITE_URL = 'https://huttage.co';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyBicErnm5MQhQ9TEC8PHfQoBxQZEdv7v40';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
'BKIjUY4LcuSLf4-hTV2ul7Q6craPxLM-whBUpnUpyYkGP6fCZNo64A0v-CoRc4jokpUYkHQ2ez4yOBJUxlZcfm4';

export const RECAPTCHASITEKEY = '6LeBwzApAAAAALwMv-jikVm3KBiLHHWR4-3gD-7q';
export const API_KEY = 'AIzaSyAWbJFJib1rCtM94YEZb_0YY6xJTiX_UiQ';
export const AUTH_DOMAIN = 'huttage-19f8d.firebaseapp.com';
export const PROJECT_ID = 'huttage-19f8d';
export const STORAGE_BUCKET = 'huttage-19f8d.appspot.com';
export const MESSAGING_SENDER_ID = '831635362053';
export const APP_ID = '1:831635362053:web:f7217ee67ec85efdf4e76c';
export const MEASUREMENT_ID = 'G-VV140SZ7BZ';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
